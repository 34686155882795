<template>
  <div class="affiliation p-6 md:p-8">
    <div class="container mx-auto">
      <div class="flex items-center mb-4">
        <h3 class="text-gray-400 text-3xl font-medium">
          {{ $t('affiliation._') }}
        </h3>
      </div>

      <ul class="list-reset flex flex-col md:flex-row border-b mb-6 border-gray-400">
        <router-link
          v-for="tab in tabsSidebar"
          :key="tab.id"
          style="margin-bottom: -0.175em;"
          :class="[
            'inline-flex py-2 px-4',
            tab === currentTab || tab.id === currentTab.parent ? 'font-medium text-white border-b-4 border-indigo-600' : 'text-gray-500 hover:text-white'
          ]"
          :to="{ name: tab.id }"
        >
          <!-- eslint-disable vue/no-v-html -->
          <svg
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6 mr-2"
            v-html="tab.icon"
          />{{ tab.title }}
          <!-- eslint-enable -->
        </router-link>
      </ul>

      <component :is="tabComponent" :origin="currentTab" :info="info" />
    </div>
  </div>
</template>

<script>
import ClipboardJS from 'clipboard'
import { formatDistanceToNow, isAfter } from 'date-fns'

export default {
  name: 'Affiliation',
  data () {
    return {
      clipboard: null,
      tabID: '',
      referrer: '',
      currentLevel: 0,
      isbm: false,
      bmtitle: '',
      nextLevel: {
        rate: 0,
        numbers: 0,
        amount: 0,
        level: 2,
        timeDisplay: ''
      },
      totalF1: 0,
      totalMember: 0,
      refDistribution: { F1: 0 },
      totalRefVolume: 0,
      totalTradeCommission: 0,
      totalPackageSale: 0,
      totalPackageCommission: 0,
      totalChildrenPass: 0,
      topPackageVolume: [],
      topTradeVolume: [],
      numberOfRefTrade: 0,
      numberOfRefBuyingPackage: 0,
      totalTradeMade: 0,
      expired_at: null
    }
  },
  computed: {
    tabs () {
      return [{
        id: 'Affiliation',
        title: this.$t('affiliation.overview'),
        icon: '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>',
        component: () => import('@/components/AffiliationOverview')
      }, {
        id: 'Referrals',
        title: this.$t('affiliation.referrals'),
        icon: '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>',
        component: () => import('@/components/AffiliationReferrals')
      }, {
        id: 'PackageCommission',
        title: this.$t('affiliation.package_commission'),
        icon: '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"></path>',
        component: () => import('@/components/AffiliationPackage')
      }, {
        id: 'AffiliationUpgrade',
        title: 'Package Commission',
        icon: '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7"></path>',
        component: () => import('@/components/AffiliationUpgrade'),
        hidden: true,
        parent: 'PackageCommission'
      }, {
        id: 'TradeCommission',
        title: this.$t('affiliation.trade_commission'),
        icon: '<line x1="18" y1="20" x2="18" y2="10" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" /><line x1="12" y1="20" x2="12" y2="4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" /><line x1="6" y1="20" x2="6" y2="14" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />',
        component: () => import('@/components/AffiliationTrade')
      }]
    },
    tabsSidebar () {
      const tabs = this.tabs.filter(t => !t.hidden)
      if (this.isbm) {
        return tabs.concat(this.tabBM)
      }
      return tabs
    },
    currentTab () {
      if (!this.tabID) {
        return this.tabs[0]
      }

      if (this.tabID === this.tabBM.id) {
        return this.tabBM
      }

      const tab = this.tabs.find(t => t.id === this.tabID)
      if (!tab) {
        return this.tabs[0]
      }

      return tab
    },
    tabComponent () {
      return this.currentTab.component
    },
    tabBM () {
      return {
        id: 'AffiliationBM',
        title: this.bmtitle,
        icon: '<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"></path>',
        component: () => import('@/components/AffiliationBranch')
      }
    },
    info () {
      if (!this.currentLevel) {
        return {}
      }

      return {
        levelCurrent: this.currentLevel,
        levelExpiredAt: this.expired_at,
        levelExpiration: this.expiration,
        levelNext: this.nextLevel,
        referrer: this.referrer,
        link: this.refLink,
        isbm: this.isbm,
        referrals: this.totalMember,
        referralsDirect: this.totalF1,
        refDistribution: this.refDistribution,
        totalChildrenPass: this.totalChildrenPass,
        packageCommission: {
          sale: this.totalPackageSale,
          commission: this.totalPackageCommission
        },
        tradeCommission: {
          volume: this.totalRefVolume,
          commission: this.totalTradeCommission
        },
        topPackageVolume: this.topPackageVolume,
        topTradeVolume: this.topTradeVolume,
        numberOfRefTrade: this.numberOfRefTrade,
        numberOfRefBuyingPackage: this.numberOfRefBuyingPackage,
        totalTradeMade: this.totalTradeMade
      }
    },
    refLink () {
      return window.location.origin + this.$router.resolve({ name: 'Register', params: { ref: this.$store.getters.username } }).href
    },
    expiration () {
      if (!this.expired_at) {
        return
      }

      const expiredAt = new Date(this.expired_at)
      if (isAfter(new Date(), expiredAt)) {
        return this.$t('affiliation.expired')
      }

      return 'Expires in ' + formatDistanceToNow(expiredAt)
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler (v) {
        if (!v) {
          return
        }

        if (!v.name) {
          return
        }

        const id = v.name
        this.tabs.forEach(t => {
          if (t.id === id) {
            this.tabID = id
          }
        })

        if (!this.tabID) {
          this.tabID = id
        }
      }
    }
  },
  created () {
    this.getMyInfo()
    this.getAffiliateInfo()
  },
  mounted () {
    this.initClipboard()
  },
  beforeDestroy () {
    if (this.clipboard) {
      this.clipboard.destroy()
    }
  },
  methods: {
    initClipboard () {
      this.clipboard = new ClipboardJS('[data-clipboard-target]')

      this.clipboard.on('success', (e) => {
        this.$toast({
          type: 'success',
          text: 'Copied!'
        })
      })

      this.clipboard.on('error', (e) => {
        this.$toast({
          type: 'error',
          text: 'Could not copy'
        })
      })
    },
    getMyInfo () {
      this.$store.dispatch('affiliation/getMyInfo')
        .then(data => {
          this.referrer = data.referrer
          this.currentLevel = data.level
          this.isbm = data.isbm
          this.bmtitle = data.bmtitle

          if (data.next) {
            this.nextLevel = data.next
            return
          }

          this.nextLevel = {
            amount: '',
            numbers: '',
            rate: '',
            level: 2
          }
        })
        .catch(err => {
          this.$toast({
            type: 'error',
            text: err.message
          })
        })
    },
    getAffiliateInfo () {
      this.$store.dispatch('affiliation/getAffiliateInfo')
        .then(data => {
          this.totalMember = data.totalMember
          this.totalF1 = data.totalF1
          this.refDistribution = data.refDistribution

          this.totalRefVolume = data.totalRefVolume
          this.totalTradeCommission = data.totalTradeCommission
          this.totalPackageSale = data.totalPackageSale
          this.totalPackageCommission = data.totalPackageCommission
          this.totalChildrenPass = data.totalChildrenPass
          this.topPackageVolume = data.topPackageVolume
          this.topTradeVolume = data.topTradeVolume

          this.numberOfRefTrade = data.numberOfRefTrade
          this.numberOfRefBuyingPackage = data.numberOfRefBuyingPackage
          this.totalTradeMade = data.totalTradeMade

          this.expired_at = data.expired_at
        })
        .catch(err => {
          this.$toast({
            type: 'error',
            text: err.message
          })
        })
    }
  }
}
</script>
<style lang="scss">
.affiliation {
  .c3 {
    .c3-chart-arc path {
      stroke-width: 4;
      &:hover {
        stroke-width: 8
      }
    }

    .c3-grid line {
      stroke: #4a5568;
    }

    .c3-line {
      stroke-width: 2px;
    }

    .c3-chart-arc text {
      fill: black !important;
    }
  }
}
</style>
